import keyBy from "lodash/keyBy";

import { Models } from "@services/api";

export type Feature = {
  id: keyof Models.FacilityServicesInfo;
  name: string;
};

export const features: Feature[] = [
  {
    id: "enrollmentTrial",
    name: "Enrollment trial",
  },
  {
    id: "flexCare",
    name: "Flex care",
  },
  {
    id: "backupCare",
    name: "Back-up care",
  },
  {
    id: "weekendService",
    name: "Weekend service",
  },
  {
    id: "afterSchoolProgram",
    name: "After-school program",
  },
  {
    id: "summerCamp",
    name: "Summer camp",
  },
  {
    id: "enrichmentClasses",
    name: "Enrichment classes",
  },
];

export const servicesMap = keyBy(features, "id");
