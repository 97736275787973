import React, { useMemo } from "react";

import Checkbox from "@material-ui/core/Checkbox";
import { ControlledMultilineInputField } from "@ui-kit/InputFields";
import { useCss } from "./styles";
import { servicesMap } from "@constants/facility-services";
import Typography from "@ui-kit/Typography";
import { Models } from "@services/api";
import { Spacer } from "@ui-kit/Spacer";
import { ControlledSelectField } from "@ui-kit/Select";
import { MONTESSORI_METHODS } from "@constants/montessori-method";
import { FacilityServicesInfo } from "@models/FacilityServicesInfo";
import { ControlledBooleanField } from "@ui-kit/BooleanField";

interface IProps {
  facilityView: Models.Facility;
  setFacilityView: React.Dispatch<React.SetStateAction<Models.Facility>>;
  isClaim?: boolean;
}

export const EditProgramsAndServicesView: React.FC<IProps> = ({
  facilityView,
  setFacilityView,
  isClaim,
}) => {
  const servicesInfo = useMemo(
    () => FacilityServicesInfo.fromDto(facilityView.servicesInfo),
    [],
  );
  const css = useCss();

  const updateView = () => {
    setFacilityView((prev) => ({
      ...prev,
      servicesInfo: servicesInfo.toDto(),
    }));
  };

  const getRadioItem = (name: keyof FacilityServicesInfo) => (
    <ControlledBooleanField
      key={`servicesInfo.${name}`}
      name={`servicesInfo.${name}`}
      label={servicesMap[name]?.name}
      required={isClaim}
      defaultValue={servicesInfo[name]}
      onChange={(event) => {
        servicesInfo[name] = (event.target.value === "yes") as never;
        updateView();
      }}
    />
  );

  return (
    <div data-test="facility-operating-details-editor">
      <Typography variant="h5" align="center" css={css.sectionTitle}>
        Education philosophy
      </Typography>
      <Spacer size="small" />

      <ControlledSelectField
        items={MONTESSORI_METHODS}
        labelProp="name"
        label="Montessori method"
        allowEmpty
        name="servicesInfo.montessoriMethod"
        defaultValue={servicesInfo.montessoriMethod}
        onChange={(event) => {
          servicesInfo.montessoriMethod = event.value.id;
          updateView();
        }}
      />

      <div
        css={css.checkRow}
        onClick={() => {
          servicesInfo.regioEmiliaMethod = !servicesInfo.regioEmiliaMethod;
          updateView();
        }}
        data-test="regioEmiliaMethod-check"
      >
        <Checkbox color="primary" checked={!!servicesInfo.regioEmiliaMethod} />
        <Typography>Reggio Emilia method</Typography>
      </div>
      <div
        css={css.checkRow}
        onClick={() => {
          servicesInfo.waldorfMethod = !servicesInfo.waldorfMethod;
          updateView();
        }}
        data-test="waldorfMethod-check"
      >
        <Checkbox color="primary" checked={!!servicesInfo.waldorfMethod} />
        <Typography>Waldorf method</Typography>
      </div>
      <div
        css={css.checkRow}
        onClick={() => {
          servicesInfo.projectBasedLearning =
            !servicesInfo.projectBasedLearning;
          updateView();
        }}
        data-test="projectBasedLearning-check"
      >
        <Checkbox
          color="primary"
          checked={!!servicesInfo.projectBasedLearning}
        />
        <Typography>Project-based learning</Typography>
      </div>
      <div
        css={css.checkRow}
        onClick={() => {
          servicesInfo.playBasedLearning = !servicesInfo.playBasedLearning;
          updateView();
        }}
        data-test="playBasedLearning-check"
      >
        <Checkbox color="primary" checked={!!servicesInfo.playBasedLearning} />
        <Typography>Play-based learning</Typography>
      </div>

      <Spacer size="medium" />

      <Typography variant="h5" align="center" css={css.sectionTitle}>
        Programs & services
      </Typography>
      <Spacer size="small" />

      {getRadioItem("enrollmentTrial")}
      <ControlledMultilineInputField
        defaultValue={servicesInfo.enrollmentTrialDescription}
        onChange={(event) => {
          servicesInfo.enrollmentTrialDescription = event.target.value;
          updateView();
        }}
        placeholder="About enrollment trial"
        name="servicesInfo.enrollmentTrialDescription"
      />

      <Spacer size="small" />

      {getRadioItem("flexCare")}
      <ControlledMultilineInputField
        defaultValue={servicesInfo.flexCareDescription}
        onChange={(event) => {
          servicesInfo.flexCareDescription = event.target.value;
          updateView();
        }}
        placeholder="About flex care"
        name="servicesInfo.flexCareDescription"
      />

      <Spacer size="small" />

      {getRadioItem("backupCare")}
      <ControlledMultilineInputField
        defaultValue={servicesInfo.backupCareDescription}
        onChange={(event) => {
          servicesInfo.backupCareDescription = event.target.value;
          updateView();
        }}
        placeholder="About back-up care"
        name="servicesInfo.backupCareDescription"
      />

      <Spacer size="small" />

      {getRadioItem("weekendService")}
      <ControlledMultilineInputField
        defaultValue={servicesInfo.weekendServiceDescription}
        onChange={(event) => {
          servicesInfo.weekendServiceDescription = event.target.value;
          updateView();
        }}
        placeholder="About weekend service"
        name="servicesInfo.weekendServiceDescription"
      />

      <Spacer size="small" />

      {getRadioItem("afterSchoolProgram")}
      <ControlledMultilineInputField
        defaultValue={servicesInfo.afterSchoolProgramDescription}
        onChange={(event) => {
          servicesInfo.afterSchoolProgramDescription = event.target.value;
          updateView();
        }}
        placeholder="About after school program"
        name="servicesInfo.afterSchoolProgramDescription"
      />

      <Spacer size="small" />

      {getRadioItem("summerCamp")}
      <ControlledMultilineInputField
        defaultValue={servicesInfo.summerCampDescription}
        onChange={(event) => {
          servicesInfo.summerCampDescription = event.target.value;
          updateView();
        }}
        placeholder="About summer camp"
        name="servicesInfo.summerCampDescription"
      />

      <Spacer size="small" />

      {getRadioItem("enrichmentClasses")}
      <ControlledMultilineInputField
        defaultValue={servicesInfo.enrichmentClassesDescription}
        onChange={(event) => {
          servicesInfo.enrichmentClassesDescription = event.target.value;
          updateView();
        }}
        placeholder="About enrichment classes"
        name="servicesInfo.enrichmentClassesDescription"
      />
    </div>
  );
};
