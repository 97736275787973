import isNil from "lodash/isNil";

import { Models } from "@services/api";

export class FacilityServicesInfo {
  private _montessoriMethod: Models.MontessoriMethod | null = null;
  private _regioEmiliaMethod: boolean | null = null;
  private _waldorfMethod: boolean | null = null;
  private _projectBasedLearning: boolean | null = null;
  private _playBasedLearning: boolean | null = null;
  private _enrollmentTrial: boolean | null = null;
  private _enrollmentTrialDescription: string | null = null;
  private _flexCare: boolean | null = null;
  private _flexCareDescription: string | null = null;
  private _backupCare: boolean | null = null;
  private _backupCareDescription: string | null = null;
  private _weekendService: boolean | null = null;
  private _weekendServiceDescription: string | null = null;
  private _afterSchoolProgram: boolean | null = null;
  private _afterSchoolProgramDescription: string | null = null;
  private _summerCamp: boolean | null = null;
  private _summerCampDescription: string | null = null;
  private _enrichmentClasses: boolean | null = null;
  private _enrichmentClassesDescription: string | null = null;

  get montessoriMethod(): Models.MontessoriMethod | null {
    return this._montessoriMethod;
  }
  set montessoriMethod(v: Models.MontessoriMethod | null | undefined) {
    this._montessoriMethod = !isNil(v) ? v : null;
  }

  get regioEmiliaMethod(): boolean | null {
    return this._regioEmiliaMethod;
  }
  set regioEmiliaMethod(v: boolean | null | undefined) {
    this._regioEmiliaMethod = !isNil(v) ? v : null;
  }

  get waldorfMethod(): boolean | null {
    return this._waldorfMethod;
  }
  set waldorfMethod(v: boolean | null | undefined) {
    this._waldorfMethod = !isNil(v) ? v : null;
  }

  get projectBasedLearning(): boolean | null {
    return this._projectBasedLearning;
  }
  set projectBasedLearning(v: boolean | null | undefined) {
    this._projectBasedLearning = !isNil(v) ? v : null;
  }

  get playBasedLearning(): boolean | null {
    return this._playBasedLearning;
  }
  set playBasedLearning(v: boolean | null | undefined) {
    this._playBasedLearning = !isNil(v) ? v : null;
  }

  get enrollmentTrialDescription(): string | null {
    return this._enrollmentTrialDescription;
  }
  set enrollmentTrialDescription(v: string | null | undefined) {
    this._enrollmentTrialDescription = !isNil(v) ? v : null;
  }

  get flexCareDescription(): string | null {
    return this._flexCareDescription;
  }
  set flexCareDescription(v: string | null | undefined) {
    this._flexCareDescription = !isNil(v) ? v : null;
  }

  get backupCareDescription(): string | null {
    return this._backupCareDescription;
  }
  set backupCareDescription(v: string | null | undefined) {
    this._backupCareDescription = !isNil(v) ? v : null;
  }

  get weekendServiceDescription(): string | null {
    return this._weekendServiceDescription;
  }
  set weekendServiceDescription(v: string | null | undefined) {
    this._weekendServiceDescription = !isNil(v) ? v : null;
  }

  get afterSchoolProgramDescription(): string | null {
    return this._afterSchoolProgramDescription;
  }
  set afterSchoolProgramDescription(v: string | null | undefined) {
    this._afterSchoolProgramDescription = !isNil(v) ? v : null;
  }

  get summerCampDescription(): string | null {
    return this._summerCampDescription;
  }
  set summerCampDescription(v: string | null | undefined) {
    this._summerCampDescription = !isNil(v) ? v : null;
  }

  get enrichmentClassesDescription(): string | null {
    return this._enrichmentClassesDescription;
  }
  set enrichmentClassesDescription(v: string | null | undefined) {
    this._enrichmentClassesDescription = !isNil(v) ? v : null;
  }

  get enrollmentTrial(): boolean | null {
    return this._enrollmentTrial;
  }
  set enrollmentTrial(v: boolean | null | undefined) {
    this._enrollmentTrial = !isNil(v) ? v : null;
  }

  get flexCare(): boolean | null {
    return this._flexCare;
  }
  set flexCare(v: boolean | null | undefined) {
    this._flexCare = !isNil(v) ? v : null;
  }

  get backupCare(): boolean | null {
    return this._backupCare;
  }
  set backupCare(v: boolean | null | undefined) {
    this._backupCare = !isNil(v) ? v : null;
  }

  get weekendService(): boolean | null {
    return this._weekendService;
  }
  set weekendService(v: boolean | null | undefined) {
    this._weekendService = !isNil(v) ? v : null;
  }

  get afterSchoolProgram(): boolean | null {
    return this._afterSchoolProgram;
  }
  set afterSchoolProgram(v: boolean | null | undefined) {
    this._afterSchoolProgram = !isNil(v) ? v : null;
  }

  get summerCamp(): boolean | null {
    return this._summerCamp;
  }
  set summerCamp(v: boolean | null | undefined) {
    this._summerCamp = !isNil(v) ? v : null;
  }

  get enrichmentClasses(): boolean | null {
    return this._enrichmentClasses;
  }
  set enrichmentClasses(v: boolean | null | undefined) {
    this._enrichmentClasses = !isNil(v) ? v : null;
  }

  public toDto(): Models.FacilityServicesInfo {
    return {
      montessoriMethod: this.montessoriMethod,
      regioEmiliaMethod: this.regioEmiliaMethod,
      waldorfMethod: this.waldorfMethod,
      projectBasedLearning: this.projectBasedLearning,
      playBasedLearning: this.playBasedLearning,
      enrollmentTrialDescription: this.enrollmentTrialDescription,
      flexCareDescription: this.flexCareDescription,
      backupCareDescription: this.backupCareDescription,
      weekendServiceDescription: this.weekendServiceDescription,
      afterSchoolProgramDescription: this.afterSchoolProgramDescription,
      summerCampDescription: this.summerCampDescription,
      enrichmentClassesDescription: this.enrichmentClassesDescription,
      enrollmentTrial: this.enrollmentTrial,
      flexCare: this.flexCare,
      backupCare: this.backupCare,
      weekendService: this.weekendService,
      afterSchoolProgram: this.afterSchoolProgram,
      summerCamp: this.summerCamp,
      enrichmentClasses: this.enrichmentClasses,
    };
  }

  static fromDto(dto: Models.FacilityServicesInfo): FacilityServicesInfo {
    const servicesInfo = new FacilityServicesInfo();

    servicesInfo.montessoriMethod = dto.montessoriMethod;
    servicesInfo.regioEmiliaMethod = dto.regioEmiliaMethod;
    servicesInfo.waldorfMethod = dto.waldorfMethod;
    servicesInfo.projectBasedLearning = dto.projectBasedLearning;
    servicesInfo.playBasedLearning = dto.playBasedLearning;
    servicesInfo.enrollmentTrialDescription = dto.enrollmentTrialDescription;
    servicesInfo.flexCareDescription = dto.flexCareDescription;
    servicesInfo.backupCareDescription = dto.backupCareDescription;
    servicesInfo.weekendServiceDescription = dto.weekendServiceDescription;
    servicesInfo.afterSchoolProgramDescription =
      dto.afterSchoolProgramDescription;
    servicesInfo.summerCampDescription = dto.summerCampDescription;
    servicesInfo.enrichmentClassesDescription =
      dto.enrichmentClassesDescription;
    servicesInfo.enrollmentTrial = dto.enrollmentTrial;
    servicesInfo.flexCare = dto.flexCare;
    servicesInfo.backupCare = dto.backupCare;
    servicesInfo.weekendService = dto.weekendService;
    servicesInfo.afterSchoolProgram = dto.afterSchoolProgram;
    servicesInfo.summerCamp = dto.summerCamp;
    servicesInfo.enrichmentClasses = dto.enrichmentClasses;

    return servicesInfo;
  }
}
