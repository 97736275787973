import { Interpolation } from "@emotion/react";

import { ITheme } from "@theme";
import { Classes } from "./types";

export const useCss = (): Record<Classes, Interpolation<ITheme>> => ({
  radioLabel: {
    flex: 1,
  },

  radioContainer: {
    alignItems: "center",
    flexDirection: "row",
    marginBottom: 10,
    width: "100%",
  },

  radio: {
    paddingBottom: 4,
    paddingTop: 4,
  },

  group: {
    flexDirection: "row",
    marginLeft: 10,

    "& label:last-child": {
      marginRight: 0,
    },
  },

  checkRow: {
    alignItems: "center",
    cursor: "pointer",
    display: "flex",
    marginLeft: -9,
  },

  sectionTitle: {
    backgroundColor: "rgba(0, 0, 0, .06)",
    padding: 7,
  },
});
