import keyBy from "lodash/keyBy";

import { Models } from "@services/api";

type MontessoriMethod = {
  id: Models.MontessoriMethod;
  name: string;
};

export const MONTESSORI_METHODS: MontessoriMethod[] = [
  {
    id: Models.MontessoriMethod.Yes,
    name: "yes",
  },
  {
    id: Models.MontessoriMethod.Ami,
    name: "AMI affiliated",
  },
  {
    id: Models.MontessoriMethod.Ams,
    name: "AMS affiliated",
  },
  {
    id: Models.MontessoriMethod.AmsPathway,
    name: "AMS accredited",
  },
];

export const montessoriMethodsMap = keyBy(MONTESSORI_METHODS, "id");
